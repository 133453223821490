* {
  box-sizing: border-box;
}

body {
  background: #654ea3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #654ea3,
    #eaafc8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #654ea3,
    #eaafc8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
  color: #ffffff;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: 960px;
  margin: auto;
  position: relative;
}

.title {
  margin: 0;
  margin: 0 0 15px 0;
  font-family: "Baloo Chettan";
}

.description {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  margin: 0 0 30px 0;
  font-family: "Montserrat";
}

.pro-tip {
  text-transform: uppercase;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 4px;
  font-size: 11px;
  font-weight: bold;
  font-family: "Montserrat";
  margin: 0 0 20px 0;
  text-align: center;
}

.key {
  background: #ffffff;
  color: #333333;
  display: inline-block;
  border-radius: 3px;
  padding: 4px 8px;
  margin-left: 4px;
  font-family: "Montserrat";
}

.link {
  color: #ffffff;
}

.github-link {
  position: absolute;
  top: 10px;
  right: 10px;
}
