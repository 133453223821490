.key-preview-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.key-preview {
  background: #ffffff;
  opacity: 0.8;
  text-align: center;
  border-radius: 4px;
  padding: 10px 20px;
  min-width: 135px;
  color: #333333;
}
