.language-selector {
  font-size: 13px;
  margin-bottom: 20px;
  text-align: center;
}

.select-control {
  margin-bottom: 5px;
}

.select-control label {
  margin-right: 5px;
}

.select-control select {
  height: 30px;
  margin-right: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  font-size: 15px;
  color: #ffffff;
}

.language-selector .link {
  display: inline-block;
}
